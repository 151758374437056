export default class ShipmentEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#shipment_uuid") ? (elem.querySelector("#shipment_uuid") as HTMLInputElement).value : null,
            ticket_number: elem.querySelector("#shipment_ticket_number").value,
            receiver_name: elem.querySelector("#shipment_receiver_name").value,
            receiver_company: elem.querySelector("#shipment_receiver_company").value,
            logistics_company: elem.querySelector("#shipment_logistics_company").value,
            tracking_number: elem.querySelector("#shipment_tracking_number").value,
            valid_from: elem.querySelector("#shipment_valid_from").value,
            valid_to: elem.querySelector("#shipment_valid_to").value,
            comment: elem.querySelector("#shipment_comment").value,
        }
    }
}